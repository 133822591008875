import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "looplex-knowledge-base",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#looplex-knowledge-base",
        "aria-label": "looplex knowledge base permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looplex Knowledge Base`}</h1>
    <h2 {...{
      "id": "guias-conceituais",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#guias-conceituais",
        "aria-label": "guias conceituais permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Guias Conceituais`}</h2>
    <p>{`Explicações gerais dos conceitos de Lawtex de nível superior. São úteis construir a compreensão de um tópico específico.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/conceptual-guides/data-extraction"
        }}>{`Extração de Dados Inteligente`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/conceptual-guides/graph"
        }}>{`Looplex Grap`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/conceptual-guides/computational-law-and-expert-systems"
        }}>{`Direito Computacional e Sistema Especialista`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/conceptual-guides/what-is-a-legal-knowledge-engineer"
        }}>{`O que é Engenharia Jurídica`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/conceptual-guides/modular-programming-and-platform-engineering"
        }}>{`Programação modular`}</a></li>
    </ol>
    <h2 {...{
      "id": "common-data-model",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#common-data-model",
        "aria-label": "common data model permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Common Data Model`}</h2>
    <p>{`Explicações gerais dos conceitos de Common Data Model.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/what-is-semantic-data"
        }}>{`O que são dados semânticos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/semantic-reference-model"
        }}>{`Modelo Semântico de Referência`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/what-is-legal-common-data-model"
        }}>{`Common Data Model jurídico`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/entities-overview"
        }}>{`Visão Geral das Entidades`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/legal-data-objects-and-complex-entities"
        }}>{`Legal Data Objects e Entidades Complexas`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/legal-data-objects-template-portfolio-solution"
        }}>{`Templates, Portfolios e Soluções`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/common-data-model/legal-data-object-tasks"
        }}>{`Classificação de Tarefas`}</a></li>
    </ol>
    <h2 {...{
      "id": "legal-design",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#legal-design",
        "aria-label": "legal design permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Legal Design`}</h2>
    <p>{`Guias práticos sobre Legal Design.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/taxonomies-and-managed-metadata"
        }}>{`Design de Metadados`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/custom-fields-on-managed-metadata"
        }}>{`Custom Fields`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/document-general-layout"
        }}>{`Layout de Documentos`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/intro-legal-content-topology"
        }}>{`Topologias de conteúdo jurídico`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/restatement-mapping"
        }}>{`Reformulação (Restatement)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/design-framework/overview-visual-law"
        }}>{`Visual Law Overview`}</a></li>
    </ol>
    <h2 {...{
      "id": "tutoriais",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tutoriais",
        "aria-label": "tutoriais permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Tutoriais`}</h2>
    <p>{`Guias práticos com passo a passo para te auxiliar a atingir um objetivo específico. São úteis quando você está tentando completar alguma tarefa.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/tutorials/first-steps-in-lawtex"
        }}>{`Primeiros passos com Lawtex`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/tutorials/using-snippets-vscode"
        }}>{`Como instalar snippets`}</a></li>
    </ol>
    <h2 {...{
      "id": "looplex-flows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#looplex-flows",
        "aria-label": "looplex flows permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looplex Flows`}</h2>
    <p>{`O Looplex Flows é um recurso de automação inteligente low-code   construído em cima de motores dinâmicos de BPMN, DMN e CMMN, como o Flowable, runbooks de automação do Looplex Code, API Zapier e outros.   Eles fornecem orquestração e execução de fluxos de trabalho de LegalOps automatizados em uma plataforma de Gerenciamento de Processos de Negócios (BPM) para engenheiros jurídicos, desenvolvedores e usuários corporativos.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/workflows/how-to-create-a-workflow"
        }}>{`Como criar um workflow`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/workflows/flowable-fundamentals"
        }}>{`Fundamentos do flowable`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/workflows/using-timers-in-flowable"
        }}>{`Trabalhando com timers no flowable`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/workflows/approval-overview"
        }}>{`Fluxo de Aprovações`}</a></li>
    </ol>
    <h2 {...{
      "id": "looplex-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#looplex-code",
        "aria-label": "looplex code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Looplex Code`}</h2>
    <p>{`Descrições técnicas e detalhadas de como o Looplex Code funciona. O Code representa a versão 2.0 do motor de construção de lógica jurídica da Looplex, em substituição à versão 1.0 do Assembler, que usava exclusivamente Lawtex.
O Looplex Code oferece um serviço de automação de engenharia jurídica baseado em nuvem, que permite (a) implantar e gerenciar infraestrutura de execução de lógica jurídica como código, reproduzível e consistente; (b) criação de automações baseada em eventos; (c) orquestrar e integrar a automação de conteúdo jurídico a outros serviços e produtos de terceiros ou da própria Looplex (inclusive com soluções desenvolvidas na v.1.0 do Assembler em Lawtex).`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/code/office-render"
        }}>{`Codificação com Office Render`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/code/data-extraction-input-services"
        }}>{`Input de dados`}</a></li>
    </ol>
    <h2 {...{
      "id": "lawtex-legado",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lawtex-legado",
        "aria-label": "lawtex legado permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lawtex (legado)`}</h2>
    <p>{`Descrições técnicas e detalhadas de como programar lógica jurídica em Lawtex. São úteis quando você precisa de informações detalhadas sobre algum aspecto específico da linguagem. O uso de Lawtex está sendo progressivamente deprecado até 2024 em favor de novos recursos de programação e lógica jurídica acessíveis pelo Looplex Code.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/assembler-lawtex/templates-list"
        }}>{`Templates de lógica`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/assembler-lawtex/operators-page"
        }}>{`Lista de Operadore`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/assembler-lawtex/tube-list"
        }}>{`Tubes`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/assembler-lawtex/lawsty"
        }}>{`Folha de estilos - Lawsty`}</a></li>
    </ol>
    <h2 {...{
      "id": "novidades",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#novidades",
        "aria-label": "novidades permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Novidades`}</h2>
    <p>{`Confira as atualizações mais recentes da Plataforma Looplex.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/pt-BR/technical/whats-new/release-notes-list"
        }}>{`O que há de novo na Looplex`}</a></li>
    </ol>
    <h2 {...{
      "id": "links-externos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#links-externos",
        "aria-label": "links externos permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Links Externos`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.looplex.com.br/"
        }}><strong parentName="a">{`Looplex Docs`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://looplex.com.br/academy/"
        }}><strong parentName="a">{`Looplex Academy`}</strong></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://blog.looplex.com/"
        }}>{`Blog`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pt.stackoverflow.com/questions/tagged/lawtex"
        }}>{`Lawtex Stack Overflow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.looplex.com/"
        }}>{`Documentação API`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      